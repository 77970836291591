import React, {useState, useRef} from "react"
import {Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter} from "reactstrap"
import Breadcrumbs from "../../components/@vuexy/breadCrumbs/BreadCrumb"
import StockLineChartComponent from "./components/line"
import "../../assets/scss/plugins/extensions/recharts.scss"
import DataTable from "react-data-table-component";
import {Line} from "react-chartjs-2"
import * as Icon from "react-feather"
import Flatpickr from "react-flatpickr";
import PerfectScrollbar from 'react-perfect-scrollbar'
import axios from 'axios';
import {func} from "prop-types";
import "react-perfect-scrollbar/dist/css/styles.css"
import "flatpickr/dist/themes/light.css";
import "../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import Checkbox from "../../components/@vuexy/checkbox/CheckboxesVuexy";
import {Check} from "react-feather";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {withRouter, useLocation} from 'react-router-dom';
import Carousel from "../stock/detail/carousel"
import moment from 'moment';
import "../../assets/scss/custom/detail.scss";

let $primary = "#16B2AE",
  $success = "#28C76F",
  $info = "#00cfe8",
  $warning = "#FF9F43",
  $danger = "#EA5455",
  colors = [$primary, $success, $info, $warning, $danger]

const apiUrl = 'https://samong-app.et.r.appspot.com/api';
const username = 'bst-service';
const password = 'P@88w0rdbsT';

let basicAuth = 'Basic ' + btoa(username + ':' + password);
let modelLists = [];
let stockLists = [];
let stockDetail = [];
let stockDetailMapName = [];
let backTests = [];
let backTestsMap = [];
const drpk = null;

const config = {
  headers: {
    'Authorization': basicAuth,
    'Content-Type': 'multipart/form-data;',
  }
}

const datatableStyles = {
  rows: {
    style: {}
  },
  headCells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '0px',
      textAlign: 'center',
    },
  },
  cells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '0px',
      textAlign: 'center',
    },
  },
};


const tableColumns = [
  {
    name: "ครั้ง", selector: (row, index) => {
      if (index > 0) {
        return index++;
      } else {
        return '';
      }
      return ++index;
    },
    sortable: false,
    width: "6%"
  },
  {
    name: "วันที่ซื้อ", selector: "open_time", sortable: false, width: "16%"
  },
  {
    name: "ราคาซื้อ", selector: (row, index) => {
      if (index > 0) {
        let digits = 2
        if(row.stock_group === 'crypto'){
          digits = 4
        }
        return parseFloat(row.open_price).toFixed(digits);
      } else {
        return '';
      }
    },
    sortable: false,
    width: "12%"
  },
  {
    name: "วันที่ขาย",
    selector: "close_time",
    sortable: false,
    width: "16%"
  },
  {
    name: "ราคาขาย", selector: (row, index) => {
      if (index > 0) {
        let digits = 2
        if(row.stock_group === 'crypto'){
          digits = 4
        }
        return parseFloat(row.close_price).toFixed(digits);
      } else {
        return '';
      }
    },
    sortable: false, width: "12%"
  },
  {
    name: "กำไร",
    selector: (row, index) => {
      let profit = row.profit;
      let digits = 2
      if(row.stock_group === 'crypto'){
        digits = 4
      }
      if (profit >= 0) {
        return (<h6 className="text-success">{parseFloat(profit).toFixed(digits)}</h6>);
      } else if (profit < 0) {
        return (<h6 className="text-danger">{parseFloat(profit).toFixed(digits)}</h6>);
      }
    },
    sortable: false,
    width: "12%"
  },
  {
    name: "ซื้อแล้วขึ้น",
    selector: (row, index) => {
      if (index > 0) {
        if (row.before_buy === 'หุ้นขึ้น') {
          return <Icon.ArrowUp size={16} className="mr-4 fonticon-wrap success" style={{marginLeft: '10px'}}/>
        } else {
          return <Icon.ArrowDown size={16} className="mr-4 fonticon-wrap danger" style={{marginLeft: '10px'}}/>
        }
      } else {
        let res = parseFloat((row.before_buy / row.count_row) * 100).toFixed(2);
        return (res > 50) ? <div className="text-success">{res}%</div> : <div className="text-danger">{res}%</div>;
      }

    },
    sortable: false,
    width: "12%",
  },
  {
    name: "ขายแล้วลง",
    selector: (row, index, a) => {
      if (index > 0) {
        if (row.before_sell === 'หุ้นขึ้น') {
          return <Icon.ArrowUp size={16} className="mr-4 fonticon-wrap danger" style={{marginLeft: '10px'}}/>
        } else {
          return <Icon.ArrowDown size={16} className="mr-4 fonticon-wrap success" style={{marginLeft: '10px'}}/>
        }
      } else {
        let res = parseFloat((row.before_sell / row.count_row) * 100).toFixed(2);
        return (res > 50) ? <div className="text-success">{res}%</div> : <div className="text-danger">{res}%</div>;
      }

    },
    sortable: false,
    width: "12%"
  },
]

function isValidDate(dateString) {
  // First check for the pattern
  if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(dateString))
    return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12)
    return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

class StockDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      modelLists: [],
      backTestsMaps: [],
      stockDetailMapName: [],
      dateStart: new Date('2020-12-01'),
      dateEnd: new Date(),
      dateStartText: '2020-12-01',
      dateEndText: '',
      stockName: '',
      modelName: '',
      modelDisplays: [],
      stockFullName: null,
      drpk: null,
      dashboard_list: {},
      dashboard_lists: [],
      load_dashboard_lists: false,
      currentStock: '',
      stockList: {}
    };
  }


  getStockList(stockName) {
    let stockList;
    let formData = new FormData();
    formData.append('stock_name', '');
    formData.append('stock_group', '');
    axios.post(apiUrl + '/stock_list', formData, config).then((response) => {
      stockList = response.data.data_response.find(stockList => stockList.stock === stockName);
      this.setState({stockList: stockList});
    });
  }

  // convertDate = (date) => {
  //   if (typeof date == 'undefined') {
  //     return false;
  //   }
  //   if (typeof date == 'object') {
  //     var yyyy = date.getFullYear().toString();
  //     var mm = (date.getMonth() + 1).toString();
  //     var dd = date.getDate().toString();
  //
  //     var mmChars = mm.split('');
  //     var ddChars = dd.split('');
  //
  //     return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
  //   }
  // }

  convertDate = (date) => {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
    // let result = (ddChars[1] ? dd : "0" + ddChars[0]) + '/' + (mmChars[1] ? mm : "0" + mmChars[0]) + '/' + yyyy;
    // return result;
  }

  convertDateDDMMYY = (date) => {
    if (typeof date === 'undefined') {
      return false;
    } else if (typeof date === 'object') {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth() + 1).toString();
      var dd = date.getDate().toString();

      var mmChars = mm.split('');
      var ddChars = dd.split('');

      let result = (ddChars[1] ? dd : "0" + ddChars[0]) + '/' + (mmChars[1] ? mm : "0" + mmChars[0]) + '/' + yyyy;
      return result;
    }
  }

  getModelLists = (stockName) => {
    console.info('load getModelLists');
    let formData = new FormData();
    formData.append('stock_name', stockName);
    axios.post(apiUrl + '/model_list', formData, config).then((response) => {
      modelLists = response.data.data_response;
      this.setState({modelLists: modelLists});
    });
  }

  lineToggle = (e, key) => {
    let modelDisplaysTemp = this.state.modelDisplays;
    modelDisplaysTemp[key + 1].display = !modelDisplaysTemp[key + 1].display;
    this.setState({modelDisplays: modelDisplaysTemp});

  }

  getStockDetail = (stockName, modelName, dateStart, dateEnd) => {
    stockDetailMapName = [];
    this.stockDetailMapName = [];
    this.setState({stockDetailMapName: []})
    let formData = new FormData();
    formData.append('stock_name', stockName);
    formData.append('model_name', modelName);
    formData.append('dateStart', dateStart);
    formData.append('dateEnd', dateEnd);

    var lastPoint = {
      0: {profit: null},
      1: {profit: null},
      2: {profit: null},
      3: {profit: null},
      4: {profit: null},
      5: {profit: null},
      6: {profit: null}
    };
    axios.post(apiUrl + '/stockdetail_list', formData, config).then((response) => {
        stockDetail = response.data.data_response;
        let modelNameTemp = [];
        if (response.data.record_total === 0) {
          stockDetail = [];
          this.setState({modelLists: [], backTestsMaps: [], stockDetailMapName: []})
        }
        stockDetail.map((sd, k) => {
          if (this.state.stockFullName == null) {
            this.setState({stockFullName: sd.full_name})
          }

          var sdmIndex = 0;
          var nsdn = stockDetailMapName.find((sdm, sdmI) => {
            sdmIndex = sdmI;
            return sdm.name === sd.modelname;
          });

          if (!nsdn) {
            if (this.state.modelName == '') {
              modelNameTemp.push({name: sd.modelname, display: true})
            } else {
              if (this.state.modelName === sd.modelname) {
                modelNameTemp.push({name: sd.modelname, display: true})
              } else {
                modelNameTemp.push({name: sd.modelname, display: false})
              }
            }

            stockDetailMapName.push({name: sd.modelname, data: []});
          }
        });

        stockDetail.map((sd, k) => {
          var sdmIndex = 0;

          stockDetailMapName.find((sdm, sdmI) => {
            sdmIndex = sdmI;
            return sdm.name === sd.modelname;
          });

          if (sd.modelname === 'main') {
            stockDetailMapName[sdmIndex].data.push(sd);
          } else if (sd.modelname !== 'main' && sd.signal > 0) {
            sd.red = null;
            sd.green = null;
            sd.yellow = sd.display_price;

            if (sd.profit >= 0) {
              sd.green = sd.display_price;
            }

            if (sd.profit < 0 || sd.profit == null) {
              sd.red = sd.display_price;
            }
          }
          lastPoint[sdmIndex] = sd;
          stockDetailMapName[sdmIndex].data.push(sd);

        });
        if (modelNameTemp.length != this.state.modelDisplays.length) {
          this.setState({modelDisplays: modelNameTemp});
        }
        this.setState({stockDetailMapName: stockDetailMapName});
      }
    )
    ;
  }

  DateThai = (date) => {
    let now = new Date(date);
    let thmonth = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    let dateThai = now.getDate() + " " + thmonth[now.getMonth()] + " " + (0 + now.getYear() + 1900 + 543);
    return dateThai;
  }

  getDashboardList(stockName, modelName, dateStart, dateEnd) {
    let formData = new FormData();
    formData.append('stock_name', stockName);
    formData.append('model_name', 'all');
    formData.append('dateStart', dateStart);
    formData.append('dateEnd', dateEnd);
    formData.append('sortBy', 'Profit_score');
    formData.append('sortOrder', 'desc');
    if (this.state.dashboard_lists.length > 0) {
      this.setState({dashboard_list: this.state.dashboard_lists.find(res => res.modelname === modelName)})
    }
    if (this.state.load_dashboard_lists === false) {
      axios.post(apiUrl + '/dashboard_list', formData, config).then((response) => {
        response = Object.assign({}, response.data);
        this.setState({dashboard_lists: response.data_response})
      })

      // formData.append('stock_name', stockName);
      // formData.append('model_name', modelName.toLowerCase());
      // formData.append('sortOrder', 'desc');
      // formData.append('sortBy', 'Profit_score');
      // axios.post(apiUrl + '/dashboard_list', formData, config).then((response) => {
      //   response = Object.assign({}, response.data);
      //   let data_response = response.data_response;
      //   this.setState({dashboard_list: data_response[0]});
      //   console.log('dashboard_list')
      // })

      // axios.post(apiUrl + '/model_list', formData, config).then((response) => {
      //   response = Object.assign({}, response.data);
      //   let modelLists = Object.values(response.data_response);
      //   modelLists.map((modelList) => {
      //     let formData = new FormData();
      //     formData.append('stock_name', stockName);
      //     formData.append('model_name', modelList.modelname.toLowerCase());
      //     formData.append('sortBy', 'Profit_score');
      //     formData.append('sortOrder', 'desc');
      //     if (modelList.modelname !== 'main') {
      //       axios.post(apiUrl + '/dashboard_list', formData, config).then((response) => {
      //         response = Object.assign({}, response.data);
      //         if (response.record_total > 0) {
      //           let data_response = response.data_response;
      //           data_response.map((data) => {
      //             // if (data.modelname === modelName) {
      //             //   this.setState({dashboard_list: data});
      //             // }
      //             if (this.state.dashboard_lists.length <= modelLists.length -2) {
      //               this.setState({dashboard_lists: this.state.dashboard_lists.concat(data)})
      //             }
      //           })
      //         }
      //       });
      //     }
      //   });
      //   this.setState({load_dashboard_lists:true})
      // });
    }

  }

  getBackTests(stockName, modelName, dateStart, dateEnd) {
    console.log(stockName, modelName, dateStart, dateEnd)
    let formData = new FormData();
    formData.append('stock_name', stockName);
    // formData.append('model_name', modelName);
    formData.append('dateStart', dateStart);
    formData.append('dateEnd', dateEnd);

    axios.post(apiUrl + '/backtest_list', formData, config).then((response) => {
      this.setState({backTestsMaps: []});
      backTests = response.data.data_response;
      backTestsMap = [];
      if (response.data.record_total > 0) {
        backTests.map((bt, btIndex) => {
          var btmIndex = null;
          var nsdn = backTestsMap.find((btm, btmI) => {
            btmIndex = btmI;
            return btm.modelname === bt.modelname;
          });
          if (!nsdn) {
            backTestsMap.push({modelname: bt.modelname, data: []});
          }

          nsdn = backTestsMap.find((btm, btmI) => {
            btmIndex = btmI;
            return btm.modelname === bt.modelname;
          });
          if (nsdn) {
            backTestsMap[btmIndex].data.push({
              open_time: bt.open_time,
              open_price: bt.open_price,
              close_price: bt.close_price,
              close_time: bt.close_time,
              profit: bt.profit,
              before_buy: bt.before_buy,
              before_sell: bt.before_sell,
              stock_group: this.state.stockList.stock_group,
            });
          }
        })
        backTestsMap.map((btm, btIndex) => {
          let sum = {};
          sum[btIndex] = {
            open_time: '',
            open_price: 0,
            close_time: '',
            close_price: 0,
            profit: 0,
            before_buy: 0,
            before_sell: 0,
            stock_group: this.state.stockList.stock_group,
            count_row: btm.data.length
          };

          btm.data.map((btmData, btmDataIndex) => {
            sum[btIndex].profit += btmData.profit;
            if (btmData.before_buy === 'หุ้นขึ้น') {
              sum[btIndex].before_buy = sum[btIndex].before_buy + 1;
            }
            if (btmData.before_sell === 'หุ้นลง') {
              sum[btIndex].before_sell = sum[btIndex].before_sell + 1;
            }
          });
          backTestsMap[btIndex].data.unshift(sum[btIndex]);
        });
      }
      console.log('backTestsMap',backTestsMap)
      this.setState({backTestsMaps: backTestsMap});
    });

  }

  componentWillReceiveProps(nextProps) {
    // this.setState({modelName: '', dateStart: new Date('2020-12-01'), dateEnd: new Date()})
    // this.drpk.setStartDate(moment('2020-12-01'))
    // this.drpk.setEndDate(moment())
    if (nextProps.location !== this.props.location) {
      let stockname = nextProps.location.pathname.replace('/stock/', '').toString();
      if (this.state.stockName != stockname) {
        this.setState({stockFullName: null})
      }
      let state = {stockName: stockname};
      console.log('componentWillReceiveProps')
      this.loadAllData(state);
    }
  }


  async componentDidMount() {
    if (this.props.match.params.stockName) {
      this.setState({stockFullName: null})
      console.log('componentDidMount')
      await this.loadAllData({stockName: this.props.match.params.stockName});
    }

  }

  datePickerHandleCallback = (start, end, label) => {
    console.log('componentDidMount')
    this.loadAllData({dateStart: start.toDate(), dateEnd: end.toDate()});
  }

  getStartDate = () => {
    let location = this.props.location;
    let start_date = new Date('2020-12-01');
    if (location.search !== '') {
      var search = location.search.substring(1);
      let query = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      if (query.start_date !== '' && moment(query.start_date, "YYYY-MM-DD", true).isValid()) {
        start_date = new Date(query.start_date);
      } else {
        start_date = new Date('2020-12-01');
      }
    }
    return start_date;
  }

  getEndDate = () => {
    let location = this.props.location;
    let end_date = new Date();
    if (location.search !== '') {
      var search = location.search.substring(1);
      let query = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

      if (query.end_date !== '' && moment(query.end_date, "YYYY-MM-DD", true).isValid()) {
        end_date = new Date(query.end_date);
      } else {
        end_date = new Date();
      }
    }
    return end_date;
  }

  querySetState = (state, stockName, dateStart, dateEnd) => {
    console.log('load querySetState')
    let modelName = '';
    if (this.state.currentStock !== stockName) {
      let formData = new FormData();
      formData.append('stock_name', stockName);
      formData.append('model_name', 'all');
      formData.append('sortBy', 'Profit_score');
      formData.append('sortOrder', 'desc');
      formData.append('dateStart', dateStart);
      formData.append('dateEnd', dateEnd);
      return axios.post(apiUrl + '/dashboard_list', formData, config).then((response) => {
        let firstModel = response.data.data_response[0];
        if (response.data.record_total > 0) {
          let location = this.props.location;
          if (location.search !== '') {
            let query = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            modelName = query.model;
          } else {
            modelName = firstModel?.modelname;
          }
          this.setState({modelName: modelName});
          this.setState({dashboard_list: response.data.data_response.find(data => data.modelname === modelName)});
          this.setState({dashboard_lists: response.data.data_response});
          this.setState({currentStock: stockName});
        }
      });
    } else {
      let location = this.props.location;
      if (location.search !== '') {
        let query = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        modelName = query.model;
        let firstModel = this.state.dashboard_lists.find(data => data.modelname === modelName);
        this.setState({modelName: modelName});
        this.setState({dashboard_list: firstModel});
      }
    }
    return
  }


  async loadAllData(state) {
    console.log('loadAllData');
    await this.setState(state);
    let stockName, modelName, dateStart, dateEnd, qs;
    stockName = this.state.stockName;
    modelName = this.state.modelName;
    dateStart = this.convertDate(this.state.dateStart);
    dateEnd = this.convertDate(this.state.dateEnd);
    this.getStockList(stockName);
    qs = this.querySetState(state, stockName, dateStart, dateEnd);
    if (qs) {
      console.log('find modelName')
      await qs.then(() => {
        modelName = this.state.modelName;
        console.log(stockName, modelName, dateStart, dateEnd);
        this.getStockDetail(stockName, modelName, dateStart, dateEnd);
        this.getBackTests(stockName, modelName, dateStart, dateEnd);
      })
    } else {
      console.log('has modelName')
      let location = this.props.location;
      if (location.search !== '') {
        let query = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        modelName = query.model;
        await this.getStockDetail(stockName, modelName, dateStart, dateEnd);
        await this.getBackTests(stockName, modelName, dateStart, dateEnd);
      }
    }


  }


  datePickerRangeConfig = () => {
    return {
      startDate: this.convertDateDDMMYY(this.getStartDate()),
      endDate: this.convertDateDDMMYY(this.getEndDate()),
      showDropdowns: true,
      locale: {
        format: 'DD/MM/YYYY'
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-header" style={{marginBottom: '20px'}}>
          <Row>
            <Col xs="3" sm="2" md="2" lg="1" xl="1">
              <div className="d-flex align-items-center">
                <img  className="rounded mt-25" src={this.state.stockList.logo_img} style={{width: '100%'}}/>
              </div>
            </Col>
            <Col xs="9" sm="10" md="10" lg="8" xl="9">
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{padding: '10px'}}>
                  <h3 className="content-header-title float-left mb-0">{this.state.stockFullName}</h3>
                </Col>
              </Row>
              <Row>
                <Col xs="8" sm="12" md="12" lg="12" xl="12" style={{padding: '10px'}}>
                  <h2 className="content-header-title float-left mb-0">{this.state.stockName}</h2>
                </Col>
              </Row>
            </Col>
            {/*<Col xs="12" sm="12" md="12" lg="3" xl="3" style={{padding: '10px'}}>*/}
            {/*<h5 className="text-bold-500">เริ่ม</h5>*/}
            {/*  <Flatpickr className="form-control" value={dateStart} onChange={date => this.onChangeStartDate(date)}*/}
            {/*             options={{maxDate: new Date()}}/>*/}
            {/*</Col>*/}
            {/*<Col xs="12" sm="12" md="12" lg="3" xl="3" style={{padding: '10px'}}>*/}
            {/*<h5 className="text-bold-500">สิ้นสุด</h5>*/}
            {/*<Flatpickr className="form-control" value={dateEnd} onChange={date => this.onChangeEndDate(date)}*/}
            {/*           options={{maxDate: new Date()}}/>*/}
            {/*</Col>*/}
            <Col xs="12" sm="12" md="12" lg="3" xl="2" style={{padding: '10px'}}>
              <DateRangePicker ref={(c) => {
                this.drpk = c;
              }} dateFormat="DD/MM/YY" autoApply={true} initialSettings={this.datePickerRangeConfig()}
                               onCallback={this.datePickerHandleCallback}>
                <input type="text" className="form-control col-12" style={{textAlign: "center"}}/>
              </DateRangePicker>
            </Col>
          </Row>
        </div>
        <Row>
          <Col id="detail" lg="8" md="8" sm="12" style={{paddingLeft: 0, paddingRight: 0}}>
            <div id="tt" className="box" style={{paddingLeft: 70, paddingBottom: 0}}>
              <div className="score-box">
                <span className="scorebox-shadow"></span>
                <h2 color="#ffffff" className="score">
                  {parseFloat((this.state.dashboard_list) ? this.state.dashboard_list.profit_score * 100 : 0).toFixed(0)} %
                </h2>
                <div color="#ffffff"
                     className="score-label">{(this.state.dashboard_list) ? this.state.dashboard_list.modelname : ''}</div>
                <div color="#ffffff"
                     className="score-label">{(this.state.dashboard_list) ? this.DateThai(this.state.dashboard_list.open_time) : ''}</div>
              </div>
              <div className="detail">
                <div className="space" style={{marginTop: -20}}>
                  <div className="space">
                    <div className="price-box">
                      <div color="#aeb3b7" className="price-label">จำนวนครั้ง</div>
                      <div color="#6c7172"
                           className="price">{(this.state.dashboard_list) ? this.state.dashboard_list.trade : 0}</div>
                    </div>
                    <div className="losschance-box">
                      <div color="#aeb3b7" className="losschance-label">ซื้อแล้วขึ้น</div>
                      <div color="#6c7172"
                           className="losschance">{(this.state.dashboard_list) ? parseFloat(this.state.dashboard_list.per_Buy * 100).toFixed(0) : 0}%
                      </div>
                    </div>
                    <div className="losschance-box">
                      <div color="#aeb3b7" className="losschance-label">ขายแล้วลง</div>
                      <div color="#6c7172"
                           className="losschance">{(this.state.dashboard_list) ? parseFloat(this.state.dashboard_list.per_Sell * 100).toFixed(0) : 0}%
                      </div>
                    </div>
                    <div className="losschance-box">
                      <div color="#aeb3b7" className="losschance-label">ความแม่น</div>
                      <div color="#6c7172"
                           className="losschance">{(this.state.dashboard_list) ? parseFloat(this.state.dashboard_list.acc * 100).toFixed(0) : 0}%
                      </div>
                    </div>
                  </div>

                </div>
                <div style={{marginTop: 50, marginLeft: -100}}>
                  <StockLineChartComponent primary={$primary} success={$success} danger={$danger} warning={$warning}
                                           chartsData={this.state.stockDetailMapName}
                                           modelLists={this.state.modelDisplays}/>

                </div>
                {/*<Row className={"justify-content-center"} style={{marginLeft:-30,paddingBottom:30,marginTop:-50}}>*/}
                {/*  {this.state.modelDisplays.filter(cd => cd.name !== 'main').map((model, key) =>*/}
                {/*    <Col key={key} lg="2" md="4" sm="4" className={model ? '' : 'hidden'}>*/}
                {/*      <div className="d-inline-block">*/}
                {/*        <Checkbox value={model.display} onChange={(e) => this.lineToggle(e, key)} color="$primary" icon={<Check className="vx-icon" size={16}/>} label={model.name} defaultChecked={model.display}/>*/}
                {/*      </div>*/}
                {/*    </Col>*/}
                {/*  )}*/}
                {/*</Row>*/}
              </div>
            </div>
          </Col>
          <Col lg="4" md="4" sm="12" style={{paddingLeft: 0, paddingRight: 0}}>

            <div id="tt" className="box" style={{paddingLeft: 10, paddingBottom: 46, paddingTop: 40}}>

              <Carousel dashboardLists={this.state.dashboard_lists} backTestsMaps={this.state.backTestsMaps}
                        stock={this.state.stockName} modelname={this.state.modelName} goIndex={this.state.dashboard_lists.findIndex(v => v.modelname === this.state.modelName)}></Carousel>
            </div>
          </Col>
          {/*          <Col lg="10" md="10">*/}
          {/*            <Card>*/}
          {/*              <CardHeader>*/}
          {/*                <CardTitle></CardTitle>*/}
          {/*              </CardHeader>*/}
          {/*              <StockLineChartComponent primary={$primary} success={$success} danger={$danger} warning={$warning}*/}
          {/*                                       chartsData={this.state.stockDetailMapName}*/}
          {/*                                       modelLists={this.state.modelDisplays}/>*/}
          {/*              <CardFooter>*/}
          {/*                <Row>*/}
          {/*                  {this.state.modelDisplays.filter(cd => cd.name !== 'main').map((model, key) =>*/}
          {/*                    <Col key={key} lg="2" md="4" sm="2" className={model ? '' : 'hidden'}>*/}
          {/*                      <div className="d-inline-block mr-1">*/}
          {/*                        <Checkbox*/}
          {/*                          value={model.display}*/}
          {/*                          onChange={(e) => this.lineToggle(e, key)}*/}
          {/*                          color="$primary"*/}
          {/*                          icon={<Check className="vx-icon" size={16}/>}*/}
          {/*                          label={model.name}*/}
          {/*                          defaultChecked={model.display}*/}
          {/*                        />*/}
          {/*                      </div>*/}
          {/*                    </Col>*/}
          {/*                  )}*/}
          {/*                </Row>*/}
          {/*              </CardFooter>*/}
          {/*            </Card>*/}
          {/*          </Col>*/}
          {/*          <Col lg="2" md="2">*/}
          {/*            <Card>*/}


          {/*            </Card>*/}
          {/*          </Col>*/}
        </Row>
        <Row>
          {this.state.backTestsMaps.filter(btm => btm.modelname === this.state.modelName).map((backTestsMap, key) => {
            // let mld = this.state.modelDisplays.find((ml, mlIndex) => backTestsMap.modelname === ml.name);
            // if (mld) {
            //   if (mld.display && backTestsMap.data) {
            // return (<Col xs="12" sm="12" md="12" lg="12" xl="12" >
            // </Col>);
            return (<Col xs="12" sm="12" md="12" lg="12" xl="12" key={key}>
              <Card>
                <CardHeader>
                  <CardTitle>{backTestsMap.modelname} ({backTestsMap.data.length - 1} ครั้ง)</CardTitle>
                </CardHeader>
                <CardBody>
                  <PerfectScrollbar style={{height: '300px', width: 'auto'}}>
                    <DataTable style={{width: '100%'}} data={backTestsMap.data} columns={tableColumns}
                               customStyles={datatableStyles} striped={true} responsive={true} noHeader={true}/>
                  </PerfectScrollbar>
                </CardBody>
              </Card>
            </Col>);
            //   }
            // }
          })}
        </Row>
      </React.Fragment>
    )
  }
}

export default StockDetail
