const themeConfig = {
  layout: "horizontal",
  theme: "light",
  sidebarCollapsed: false,
  navbarColor: "default",
  navbarType: "floating",
  footerType: "hidden",
  disableCustomizer: true,
  hideScrollToTop: true,
  disableThemeTour: true,
  menuTheme: "primary",
  direction: "ltr"
};

export default themeConfig;
