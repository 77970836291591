import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"

import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"

import {history} from "../../../history"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: []
  }


  componentDidMount() {

  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }


  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search"/>
          </NavLink>
          <div className={classnames("search-input", {open: this.state.navbarSearch, "d-none": this.state.navbarSearch === false})}>
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary"/>
            </div>

            <Autocomplete
              defaultValue={true}
              className="form-control"
              suggestions={[{groupTitle: 'all', searchLimit:50,data: this.props.suggestions.reverse().sort((a,b) => a.full_name - b.full_name)}]}
              filterKey="stock"
              // filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="ค้นหา..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              suggestionLimit={0}
              externalClick={e => {
                this.setState({navbarSearch: false})
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }
              }}
              customRender={(item, i, filteredData, activeSuggestion, onSuggestionItemClick, onSuggestionItemHover) => {
                const IconTag = Icon[item.icon ? item.icon : "X"]
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick('/stock/'+item.stock, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div className={classnames({"d-flex justify-content-between align-items-center": item.logo_img})}>
                      <div className="item-container d-flex">
                        {item.icon ? (<IconTag size={17}/>) : item.file ? (
                          <img src={item.file} height="36" width="28" alt={item.stock}/>
                        ) : item.logo_img ? (
                          <img className="rounded mt-25" src={item.logo_img} height="28" width="28" alt={item.stock}/>
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.stock}</p>
                          {item.full_name? (
                            <small className="text-muted">
                              {item.full_name}
                            </small>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </li>
                )
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput)
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X size={24} onClick={(e) => {
                e.stopPropagation();
                this.setState({navbarSearch: false});
                this.props.handleAppOverlay("")
              }}
              />
            </div>
          </div>
        </NavItem>
      </ul>
    )
  }
}

export default NavbarUser
