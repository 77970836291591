import React, {useState, useCallback} from "react"
import detail from "../../../views/stock/detail";
import {Col, Navbar} from "reactstrap"
import {connect} from "react-redux"
import classnames from "classnames"
// import {useAuth0} from "../../../authServices/auth0/auth0Service"
import logo from "../../../assets/img/logo/logo.png"
import logoMobile from "../../../assets/img/logo/logo_mobile.png"
import {Typeahead} from 'react-bootstrap-typeahead';
import axios from "axios"; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Route, useHistory, Link, Redirect, Router} from "react-router-dom";
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import {Row} from "reactstrap"

const apiUrl = 'https://samong-app.et.r.appspot.com/api';
const username = 'bst-service';
const password = 'P@88w0rdbsT';
let basicAuth = 'Basic ' + btoa(username + ':' + password);

const config = {
  headers: {
    'Authorization': basicAuth,
    'Content-Type': 'multipart/form-data;',
  }
}


const UserName = props => {
  let username = ""
  if (props.userdata !== undefined) {
    username = props.userdata.name
  } else if (props.user.login.values !== undefined) {
    username = props.user.login.values.name
    if (
      props.user.login.values.loggedInWith !== undefined &&
      props.user.login.values.loggedInWith === "jwt"
    ) {
      username = props.user.login.values.loggedInUser.name
    }
  } else {
    username = "John Doe"
  }

  return username
}


const ThemeNavbar = props => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [ref, setRef] = useState(false);
  // const {user} = useAuth0()
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating", "static", "sticky", "hidden"]
  const history = useHistory();

  const filterByCallback = (option, props) => (
    option.a.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
    option.b.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
  );

  if (loaded == false) {
    loadStockList();
    setLoaded(true)
  }


  function loadStockList() {
    let formData = new FormData();
    formData.append('stock_name', '');
    formData.append('stock_group', '');
    axios.post(apiUrl + '/stock_list', formData, config).then((response) => {
      let optionsData = response.data.data_response;
      setOptions(response.data.data_response);
    });
  }

  function handleSubmit(path, event) {
    console.log(path);
    history.replace({pathname: '/stock/' + path, state: {fromDashboard: true}})
    setTimeout(() => ref.clear(), 0);
  }

  function TypeaheadonKeyDown(e) {
    if (e.key === 'Enter') {
      let optionsResults = options.filter(op => op.stock.includes(ref.state.text.toUpperCase()));
      console.log(optionsResults.length);
      if (optionsResults.length === 1) {
        let path = optionsResults[0].stock;
        history.replace({pathname: '/stock/' + path, state: {fromDashboard: true}})
        setTimeout(() => ref.clear(), 0);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="content-overlay"/>
      <div className="-header-navbar-shadow"/>

      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >

        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse d-flex justify-content-between align-items-center" id="navbar-mobile">
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <Link to={'/'}>
                    <img src={logo} width="150px"/>
                  </Link>
                  {/*<h2 className="text-primary brand-text mb-0">Samong</h2>*/}
                  <div style={{marginLeft: 55, width: 400}}>

                    <Typeahead
                      labelKey="stock"
                      id="searchBar"
                      options={options}
                      // onInputChange={test}
                      placeholder="ค้นหา..."
                      onKeyDown={TypeaheadonKeyDown}
                      // selected={selected}
                      // onChange={test}
                      // inputProps={test}
                      ref={(ref) => setRef(ref)}
                      renderMenuItemChildren={(option) => (

                        <div className="item-container d-flex"  onClick={handleSubmit.bind(this, option.stock)}>
                          {option.icon ? ('') : option.file ? (
                            <img src={option.file} height="36" width="28" alt={option.stock}/>
                          ) : option.logo_img ? (
                            <img className="rounded mt-25" src={option.logo_img} height="28" width="28" alt={option.stock}/>
                          ) : null}
                          <div className="item-info ml-1">
                            <p className="align-middle mb-0">{option.stock}</p>
                            {option.full_name? (
                              <small className="text-muted">
                                {option.full_name}
                              </small>
                            ) : null}
                          </div>
                        </div>

                        // <div style={{paddingTop: 10, marginBottom: 10}} onClick={handleSubmit.bind(this, option.stock)}>
                        //   <div style={{marginBottom: 10}}>{option.stock}</div>
                        //   <div><small>{option.full_name}</small></div>
                        // </div>
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div class="d-flex align-items-center justify-content-between" style={{width: '95%'}}>
                  <div>
                    {/*<Link to={'/stock'}>*/}
                    {/*  <div style={{marginRight: 15}}>*/}
                    {/*    <img src={logoMobile} height={35}/>*/}
                    {/*  </div>*/}
                    {/*</Link>*/}
                  </div>
                  <div style={{paddingLeft:15}}>
                    <Link to={'/'}>
                      <div style={{marginRight: 15}}>
                        <img src={logo} height={35}/>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <NavbarUser
                      suggestions={options}
                      handleAppOverlay={props.handleAppOverlay}
                    />

                  </div>
                </div>)}

              <div>
              </div>
              <div>
              </div>
            </div>

          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {})(ThemeNavbar)
