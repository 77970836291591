import React, {useState} from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Row, Col, Progress, Button,

} from 'reactstrap'
import * as Icon from "react-feather"
import {Link} from "react-router-dom";


const DateThai = (date) => {
  let now = new Date(date);
  let thmonth = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  let dateThai = now.getDate() + " " + thmonth[now.getMonth()] + " " + (0 + now.getYear() + 1900 + 543);
  return dateThai;
}

const CarouselBasic = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(0)
  const [stock, setStock] = useState(0)
  const [load, setLoad] = useState(false)



  if (stock !== props.stock) {
    setStock(props.stock);

    setActiveIndex(0);
  }

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === props.dashboardLists.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? props.dashboardLists.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }


  let dashboardLists = [];

  //filter selected model
  // dashboardLists = props.dashboardLists.filter((item) => {
  //   return item.modelname !== props.modelname;
  // });
  dashboardLists = props.dashboardLists;

  //merge dashboard list with backTestModel
  dashboardLists.map((item, dblIndex) => {
    let backTestModel = props.backTestsMaps.find((btm) => {
      return btm.modelname === item.modelname;
    });
    if (backTestModel) {
      if (backTestModel.hasOwnProperty('data')) {
        // if (backTestModel.data.length > 0 && props.dashboardLists.length-1> dashboardLists.length) {
        if (backTestModel.data.length > 0) {
          // dashboardLists[dblIndex].merge({profit: backTestModel.data[0].profit})
          dashboardLists[dblIndex] = {...dashboardLists[dblIndex], ...{profit: backTestModel.data[0].profit}};
        }
      }
    }
  })

  if(load===false&&props.goIndex!==-1&&props.goIndex!==activeIndex){
    setActiveIndex(props.goIndex)
    setLoad(true)
  }



  //sort profit
  // dashboardLists = dashboardLists.sort((a, b) => b.profit - a.profit);


  const slides = dashboardLists.map(item => {
    const profit = () => {
      if (item.profit > 0) {
        return (
          <h6 className="text-success"><Icon.ArrowUp size={16} className="success" style={{marginLeft: ''}}/>
            {parseFloat(item.profit).toFixed(2)} บาท
            ({(item.profit_score > 0) ? '+' : ''}{parseFloat(item.profit_score * 100).toFixed(2)}%)
          </h6>
        )
      } else {
        return (
          <h6 className="text-danger"><Icon.ArrowDown size={16} className="success" style={{marginLeft: ''}}/>
            {parseFloat(item.profit).toFixed(2)} บาท
            ({(item.profit_score > 0) ? '-' : ''}{parseFloat(item.profit_score * 100).toFixed(2)}%)
          </h6>
        )
      }
    }

    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.id}>
        <Row className={"justify-content-center"}>
          <Col sm={3} md={9} lg={4}>จำนวนครั้ง</Col><Col sm={9} md={9} lg={8}>
          <div style={{paddingBottom: 5}}>
            <h6>{item.trade} ครั้ง</h6>
          </div>
        </Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col sm={3} md={9} lg={4}>กำไรสะสม</Col><Col sm={9} md={9} lg={8}>
          <div style={{paddingBottom: 5}}>
            {profit()}
          </div>
          {/*<Icon.ArrowUp size={16} className="mr-4 fonticon-wrap success" style={{marginLeft: '10px'}}/>*/}
          {/*{backTestModel.data[0].profit}*/}
          {/*{(profit>=0)?(<h6 className="text-success">parseFloat((backTestModel.data)?backTestModel.data[0].profit:0).toFixed(2)</h6>):(<h6 className="text-danger">parseFloat((backTestModel.data)?backTestModel.data[0].profit:0).toFixed(2)</h6>)}*/}
        </Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col sm={3} md={9} lg={4}>ความแม่น</Col><Col sm={9} md={9} lg={8}><Progress striped className="progress-xl"
                                                                                      color={(parseFloat(item.acc * 100).toFixed(0) >= 50) ? 'success' : 'warning'}
                                                                                      value={parseFloat(item.acc * 100).toFixed(0)}>{parseFloat(item.acc * 100).toFixed(0)}%</Progress></Col>
        </Row>
        {/*<Row className={"justify-content-center"}>*/}
        {/*  <Col sm={3} md={9} lg={4}>จำนวนครั้งที่กำไร</Col><Col sm={9} md={9} lg={8}>*/}
        {/*  <Progress striped className="progress-xl"*/}
        {/*            color={(parseFloat(item.profit_score * 100).toFixed(0) >= 50) ? 'success' : 'warning'}*/}
        {/*            value={parseFloat(item.profit_score * 100).toFixed(0)}>{parseFloat(item.profit_score * 100).toFixed(0)}%</Progress>*/}
        {/*</Col>*/}
        {/*</Row>*/}
        <Row className={"justify-content-center"}>
          <Col sm={3} md={9} lg={4}>ซื้อแล้วขึ้น</Col><Col sm={9} md={9} lg={8}><Progress striped
                                                                                          className="progress-xl"
                                                                                          color={(parseFloat(item.per_Buy * 100).toFixed(0) >= 50) ? 'success' : 'warning'}
                                                                                          value={parseFloat(item.per_Buy * 100).toFixed(0)}>{parseFloat(item.per_Buy * 100).toFixed(0)}%</Progress></Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col sm={3} md={9} lg={4}>ขายแล้วลง</Col><Col sm={9} md={9} lg={8}><Progress striped className="progress-xl"
                                                                                       color={(parseFloat(item.per_Sell * 100).toFixed(0) >= 50) ? 'success' : 'warning'}
                                                                                       value={parseFloat(item.per_Sell * 100).toFixed(0)}>{parseFloat(item.per_Sell * 100).toFixed(0)}%</Progress></Col>
        </Row>

        <Row className={"justify-content-center"} style={{paddingTop: 20, paddingBottom: 20}}>
          <Col>
            ผลกำไรตั้งแต่ {DateThai(item.open_time)} เป็นเงิน {parseFloat(item.profit).toFixed(2)} บาท
          </Col>
        </Row>
        <Row className={"justify-content-center"} style={{paddingTop: 20, paddingBottom: 20}}>
          <Col>
            <Link to={'/stock/' + props.stock + '?model=' + item.modelname}>
              <Button color="primary">{item.modelname}</Button>
            </Link>
          </Col>
        </Row>

      </CarouselItem>
    )
  });

  return (
    <div>
      <Carousel activeIndex={activeIndex}>
        <CarouselIndicators
          items={dashboardLists}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction='prev'
          directionText='Previous'
          onClickHandler={previous}
        />
        <CarouselControl
          direction='next'
          directionText='Next'
          onClickHandler={next}
        />
      </Carousel>

    </div>
    // next={next} previous={previous}


  )
}

export default CarouselBasic
