import React from "react"
import {Card, CardHeader, CardTitle, CardBody, Col, Row, CardFooter, Form, FormGroup, Label, Input} from "reactstrap"
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts"
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import {Check} from "react-feather";
import {tooltip} from "leaflet/dist/leaflet-src.esm";
import {stringify} from "query-string";


class StockLineChartComponent extends React.Component {
  constructor(props) {
    super(props);
    let lineRed, lineGreen;
    this.state = {
      price: 0,
      lossChance: 0,
      predictLine: 0,
      score: 0,
      modelLists: [],
    };
  }


  CustomTooltip = ({active, payload, label}) => {
    let pls = [];
    if (active&&payload) {
      return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top"
             style={{}}>
          <div className="recharts-default-tooltip" style={{margin: '0px', padding: '10px', 'background-color': 'rgba(255, 255, 255,0.7)', border: '1px solid rgb(204, 204, 204)', 'white-space': 'nowrap'}}>
            <p className="recharts-tooltip-label" style={{margin: '0px'}}>{label}</p>
            <ul className="recharts-tooltip-item-list" style={{padding: '0px', margin: '0px'}}>
              {this.props.chartsData.map((cd, k) => {
                let pl = false;
                pl = payload.find((pay, payIndex) => pay.name === cd.name);
                if (pl) {
                  pls.push(pl);
                }
              })}
              {pls
                // .sort((a, b) => b.payload.close - a.payload.close)
                // .reverse()
                .filter(v => v.name === 'main')
                .map((pl) => {
                return (
                  <li className="recharts-tooltip-item" style={{display: 'block', 'padding-top': '4px', 'padding-bottom': '4px', color: pl.stroke}}>
                  <span className="recharts-tooltip-item-name">{(pl.name!=='main')?pl.name:'ราคาปิด'}</span>
                  <span className="recharts-tooltip-item-separator"> : </span>
                  <span className="recharts-tooltip-item-value">{parseFloat(pl.payload.close).toFixed(2)}</span>
                  <span className="recharts-tooltip-item-unit"></span>

                </li>)
              })}
            </ul>
          </div>
        </div>
      );
    }
    return null;
  };

  lineOnMouseMove = (e) => {
    if (e.activePayload) {
      this.setState({price: e.activePayload[0].payload.price})
    }
  }


  render() {
    const colorBreakPoint = 3000;
    const {min, max} = this.props.chartsData.reduce((result, dataPoint) => ({min: 0, max: 100,}), {min: 0, max: 0});
    const colorBreakPointPercentage = 1;
    return (
      <div>
        <CardBody>
          {/*<Row style={{marginBottom: "20px"}}>*/}
          {/*  <Col sm="3" lg="3">*/}
          {/*    <p>Price</p>*/}
          {/*    <b>{this.state.price}</b>*/}
          {/*  </Col>*/}
          {/*  <Col sm="3" lg="3">*/}
          {/*    <p>Loss Chance</p>*/}
          {/*    <b>{this.state.lossChance}</b>*/}
          {/*  </Col>*/}
          {/*  <Col sm="3" lg="3">*/}
          {/*    <p className="text-danger">{this.state.predictLine}</p>*/}
          {/*    <b className="text-danger">Over Jitta Lines</b>*/}
          {/*  </Col>*/}
          {/*  <Col sm="3" lg="3">*/}
          {/*    <h1>{this.state.score}</h1>*/}
          {/*    <b>JITTA SCORE</b>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*{console.log(this.props.chartsData)}*/}
          <div className="recharts-wrapper">
            <ResponsiveContainer height={280}>
              <LineChart width={500} height={500} margin={{top: 5, right: 30, left: 20, bottom: 100}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis padding={{left: 10, right: 10}} dataKey="date" type="category" tick={{angle: -45}} textAnchor="end" allowDuplicatedCategory={false}/>
                <YAxis domain={['auto', 'auto']} dataKey="display_price"/>
                {/*<Legend/>*/}
                {/*<Tooltip  />*/}
                <Tooltip content={<this.CustomTooltip/>}/>
                {/*{this.props.chartsData.filter(cd => cd.name !== 'main').map((s, k) =>*/}
                {/*  <Line type="monotone" dataKey="yellow" stroke="red" connectNulls={false} dot={false}  data={s.data}*/}
                {/*        name={s.name} key={s.name+'_yellow'}/>*/}
                {/*)}*/}
                {/*{console.log(this.props.chartsData)};*/}
                {this.props.chartsData.filter(cd => cd.name !== 'main').map((s, k) => {
                  // let modelList = this.props.modelLists.find((ml, mlIndex) => s.name === ml.name);
                  // if (modelList) {
                    // if (modelList.display) {
                      return (<Line strokeWidth={1.5} isAnimationActive={false} type="monotone" dataKey="red" stroke="red" connectNulls={false} activeDot={false} dot={{r: 0.5, stroke: 'red', strokeWidth: 1}} data={s.data} name={s.name} key={s.name + '_red'}/>)
                    // }
                  // }
                })}
                {this.props.chartsData.filter(cd => cd.name !== 'main').map((s, k) => {
                  // let modelList = this.props.modelLists.find((ml, mlIndex) => s.name === ml.name);
                  // if (modelList) {
                    // if (modelList.display) {

                      return <Line strokeWidth={1.5} isAnimationActive={false} type="monotone" dataKey="green"
                                   stroke="green"
                                   connectNulls={false} activeDot={false}
                                   dot={{r: 0.5, stroke: 'green', strokeWidth: 1}}
                                   data={s.data}
                                   name={s.name} key={s.name + '_green'}/>
                    // }
                  // }
                })}
                {this.props.chartsData.filter(cd => cd.name === 'main').map((s, k) =>
                  <Line strokeWidth={1.5} isAnimationActive={false} type="monotone" legendType="star"
                        dataKey="display_price" connectNulls={false} dot={false} data={s.data}
                        name={s.name} key={s.name}/>
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </div>
    )
  }
}

export default StockLineChartComponent

